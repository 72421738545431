import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { useLocale } from '@app/hooks';

import { Breadcrumb, Layout, Sidebar } from '@app/components';
import Icons from '@app/icons';

import '@app/styles/pages/postTemplate.scss';

type PostTemplatePropTypes = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        date: string;
        path: string;
        image: any;
      };
      html: string;
    };
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            title: string;
            path: string;
            brief: string;
          };
        };
      }[];
    };
  };
};

const PostTemplate: React.FC<PostTemplatePropTypes> = ({ data }) => {
  const SCOPE_OPTIONS = {
    scope: 'pages.Posts',
  };
  const { t } = useLocale();
  const { markdownRemark, allMarkdownRemark } = data;

  if (markdownRemark === null) {
    return null;
  }

  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Helmet title={frontmatter.title} />
      <Breadcrumb
        items={[
          { path: '/', name: t('labels.home', SCOPE_OPTIONS) },
          { path: '/posts', name: t('labels.blog', SCOPE_OPTIONS) },
          { path: `/${frontmatter.path}`, name: frontmatter.title },
        ]}
      />

      <div className="post-header">
        <div className="container mx-auto px-1.5">
          <h1 className="post-header__title">{frontmatter.title}</h1>
          <div className="post-header__date">
            <Icons.Calendar />
            <span className="ml-2">{frontmatter.date}</span>
          </div>
        </div>
      </div>

      <div className="post-content">
        <div className="container mx-auto px-1.5">
          <div className="grid lg:grid-cols-4 gap-6 items-start">
            <div className="col-span-3">
              <div className="post-content__image">
                <img
                  src={frontmatter.image.childImageSharp.fluid.src}
                  alt={frontmatter.title}
                  className="w-full mb-6"
                />
              </div>

              <div
                className="post-content__description"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
            <Sidebar recentPost={allMarkdownRemark.edges} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query ($slug: String!, $lang: String!) {
    markdownRemark(
      frontmatter: { path: { eq: $slug }, lang: { eq: $lang }, type: { eq: "post" } }
    ) {
      id
      frontmatter {
        title
        date
        path
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
    allMarkdownRemark(
      filter: { frontmatter: { lang: { eq: $lang }, type: { eq: "post" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            brief
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
